var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.currtent
          ? _vm.$t("lang_editorial_company")
          : _vm.$t("lang_add_company"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.companyData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_superior_company"),
                        prop: "pid",
                      },
                    },
                    [
                      _c("treeselect", {
                        attrs: {
                          loading: _vm.parentDeptGroupSelectListLoading,
                          options: _vm.parentDeptGroupSelectList,
                          "append-to-body": "",
                          "z-index": 2999,
                          "default-expand-level": 1,
                          normalizer: _vm.menuTreeNormalizer,
                          placeholder: _vm.$t(
                            "lang_please_select_a_parent_company"
                          ),
                        },
                        model: {
                          value: _vm.companyData.pid,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "pid", $$v)
                          },
                          expression: "companyData.pid",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_company_name"),
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.companyData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "name", $$v)
                          },
                          expression: "companyData.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_company_abbreviation"),
                        prop: "shortName",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.companyData.shortName,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "shortName", $$v)
                          },
                          expression: "companyData.shortName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_company_code"),
                        prop: "groupCode",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("lang_please_enter") },
                        model: {
                          value: _vm.companyData.groupCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.companyData, "groupCode", $$v)
                          },
                          expression: "companyData.groupCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_company_type"),
                        prop: "companyType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: _vm.$t(
                              "lang_please_entry_the_company_type"
                            ),
                            clearable: "",
                          },
                          model: {
                            value: _vm.companyData.companyType,
                            callback: function ($$v) {
                              _vm.$set(_vm.companyData, "companyType", $$v)
                            },
                            expression: "companyData.companyType",
                          },
                        },
                        _vm._l(_vm.companyTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("lang_sort"), prop: "sort" } },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.companyData.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.companyData, "sort", $$v)
                              },
                              expression: "companyData.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_state"),
                            prop: "disabled",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_a_status"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.companyData.disabled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.companyData, "disabled", $$v)
                                },
                                expression: "companyData.disabled",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_enable"),
                                  value: true,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_disable_"),
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }