var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        "append-to-body": "",
        title: _vm.currtent
          ? _vm.$t("lang_editor_invoice")
          : _vm.$t("lang_add_invoice"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.invoiceData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("lang_invoice_header"),
                    prop: "title",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "title", $$v)
                      },
                      expression: "invoiceData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("lang_duty_paragraph"),
                    prop: "dutyParagraph",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.dutyParagraph,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "dutyParagraph", $$v)
                      },
                      expression: "invoiceData.dutyParagraph",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("lang_company_address"),
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "address", $$v)
                      },
                      expression: "invoiceData.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_phone"), prop: "phoneNumber" } },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "phoneNumber", $$v)
                      },
                      expression: "invoiceData.phoneNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("lang_deposit_bank"),
                    prop: "depositBank",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.depositBank,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "depositBank", $$v)
                      },
                      expression: "invoiceData.depositBank",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("lang_bank_account"),
                    prop: "bankAccount",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "input",
                    attrs: { placeholder: _vm.$t("lang_please_enter") },
                    model: {
                      value: _vm.invoiceData.bankAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.invoiceData, "bankAccount", $$v)
                      },
                      expression: "invoiceData.bankAccount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }