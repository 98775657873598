


















































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { InvoiceModel } from '@/models/organization/InvoiceModel';
import { addOrEdit } from '@/api/system/InvoiceApi';
@Component({
  name: 'InvoiceForm',
  components: {}
})
export default class InvoiceForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前数据
  @Prop({ default: null }) currtent!: InvoiceModel;
  @Prop({ default: '' }) groupId!: string;
  @Prop({ default: '' }) tenantId!: string;
  //   表单加载
  formLoading: boolean = false;
  //   表单数据
  invoiceData: any = {};
  //   验证规则
  formRules: Record<string, any> = {
    title: {
      required: true,
      message: this.$t('lang_please_enter_the_invoice_header'),
      trigger: 'blur'
    },
    dutyParagraph: {
      required: true,
      message: this.$t('lang_please_enter_the_tax_number'),
      trigger: 'blur'
    },
    address: {
      required: true,
      message: this.$t('lang_please_enter_the_company_address'),
      trigger: 'blur'
    },
    phoneNumber: {
      required: true,
      message: this.$t('lang_please_entry_phone'),
      trigger: 'blur'
    },
    depositBank: {
      required: true,
      message: this.$t('lang_please_enter_the_deposit_bank'),
      trigger: 'blur'
    },
    bankAccount: {
      required: true,
      message: this.$t('lang_please_enter_the_bank_account_number'),
      trigger: 'blur'
    }
  };

  created() {
    if (this.currtent) {
      this.invoiceData = { ...this.currtent };
    } else {
      this.invoiceData = {
        groupId: this.groupId,
        tenantId: this.tenantId,
      };
    }
  }
  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      addOrEdit(this.invoiceData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
