import HttpUtil from '@/utils/HttpUtil';

// 新增编辑发票
export const addOrEdit = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizInvoice/addOrEdit';
  return HttpUtil.post(url, params);
};

// 删除发票
export const deleteInVoice = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizInvoice/delete';
  return HttpUtil.get(url, params);
};

// 获取发票分页列表
export const getInVoicePage = (params?: any) => {
  let url = process.env.VUE_APP_SERVER_CODE + '/bizInvoice/page';
  return HttpUtil.post(url, params);
};
