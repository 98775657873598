

























































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { getInVoicePage, deleteInVoice } from '@/api/system/InvoiceApi';
import InvoiceForm from './invoice-form.vue';
@Component({
  name: 'Invoice',
  components: { InvoiceForm }
})
export default class Invoice extends Vue {
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  //   租户id
  @Prop({ default: '' }) private tenantIds!: string;
  // 公司id
  @Prop({ default: '' }) private groupIds!: string;
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  //   表格加载动画
  invoiceListLoading: boolean = false;
  //   发票列表
  invoiceList: any[] = [];
  //   是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
  // 当前选择的数据
  currtent: any = null;
  groupId: string = '';
  tenantId: string = '';

  created() {
    this.groupId = this.groupIds;
    this.tenantId = this.tenantIds;
    this.getInvoicePage();
  }
  /**
   * 新增编辑发票点击
   */
  addForm(row) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deleteInVoice({ invoiceInfoId: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getInvoicePage();
      }
    });
  }

  /**
   * 获取发票分页列表
   */
  getInvoicePage() {
    let params = {
      groupId: this.groupId,
      tenantId: this.tenantId,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.invoiceListLoading = true;
    getInVoicePage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.invoiceList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.invoiceListLoading = false;
      });
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getInvoicePage();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getInvoicePage();
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
