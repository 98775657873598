
























































































import { Component, Vue } from 'vue-property-decorator';
import { deleteOrgGroup, getCompanyPage, updateOrgGroup, getCompanyType } from '@/api/system/OrganizationApi';
import CompanyForm from './company-form.vue';
import Invoice from './invoice.vue';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'CompanyList',
  components: { CompanyForm, Invoice }
})
export default class CompanyList extends Vue {
  // 表单搜索Model
  formSearchModel: any = { name: '' };
  // 分页参数
  pageIndex: number = 1;
  // 每页大小
  pageSize: number = 10;
  // 总条数
  total: number = 0;
  // 当前页
  currentPage: number = 1;
  // 公司加载动画
  companyListLoading: boolean = false;
  //公司列表数据
  companyList: any[] = [];
  // 当前选择的数据
  currtent: any = null;
  // 是否显示新增编辑弹框
  isShowAddEdit: boolean = false;
//   是否显示发票管理
  isShowInvoice: boolean = false;
//   当前的公司id；点击发票时使用
  tenantId: string = '';
  // 公司类型列表
  companyTypeList: any[] = [];
  // 公司id 用于公司新增编辑
  groupId: string = '';
  // 公司id用于发票
  groupIds: string = '';


  mounted() {
    this.groupId = UserUtil.getCompanyData().id;
    this.getCompanyList();
    this.getCompanyType();
  }

  getCompanyType() {
    getCompanyType()
      .then((res: Record<any, any>) => {
        this.companyTypeList = res.data;
      })
  }

  /**
   * 公司列表
   */
  getCompanyList() {
    let params = {
      name: this.formSearchModel.name,
      page: {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
    };
    this.companyListLoading = true;
    getCompanyPage(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.companyList = res.data.list;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        this.companyListLoading = false;
      });
  }

  /**
   * 获取公司类型名称
   */
  getTypeName(code) {
    let name:string = ''; 
    this.companyTypeList.forEach(item => {
      if (item.code == code) {
        name = item.name;
      }
    })
    return name;
  }

  /**
   * 点击发票
   */
  invoiceClick(row) {
    this.groupIds = row.id;
    this.tenantId = row.tenantId;
    this.isShowInvoice = true;
  }

  /**
   * 启用或禁用
   */
  disabledChange(row) {
    let copyData = { ...row };
    copyData.disabled = !copyData.disabled;
    updateOrgGroup(copyData).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getCompanyList();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 删除
   */
  remove(row?: any) {
    deleteOrgGroup({ groupId: row.id }).then((res: Record<string, any>) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.getCompanyList();
      }
    });
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.pageIndex = 1;
    this.getCompanyList();
  }

  /**
   * 重置搜索按钮
   */
  handleSearchReset() {
    // 置空搜索表单
    this.pageIndex = 1;
    this.formSearchModel = { name: '' };
    this.getCompanyList();
  }

  /**
   * 添加新增编辑弹框
   */
  addForm(row?: any) {
    this.currtent = row;
    this.isShowAddEdit = true;
  }

  /**
   * 改变分页大小
   */
  changePageSize(pageSize: number) {
    if (pageSize < 1) {
      this.pageSize = 1;
    } else if (pageSize >= this.total) {
      this.pageSize = this.total;
    } else {
      this.pageSize = pageSize;
    }
    this.getCompanyList();
  }

  /**
   * 跳转至某页
   */
  changePage(page: number) {
    let totalPage = this.total / this.pageSize;
    let remainder = this.total % this.pageSize;
    if (remainder != 0) {
      totalPage++;
    }

    if (page < 1) {
      this.pageIndex = 1;
    } else if (page > totalPage) {
      this.pageIndex = totalPage;
    } else {
      this.pageIndex = page;
    }
    this.getCompanyList();
  }
}
