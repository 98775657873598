








































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { DeptModel } from '@/models/organization/DeptModel';
import { saveCompany, getCompanyTreeData } from '@/api/system/OrganizationApi';
import UserUtil from '@/utils/UserUtil';

@Component({
  name: 'DeptForm'
})
export default class DeptForm extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 公司类型
  @Prop({ default: [] }) companyTypeList;
  // 当前数据
  @Prop({ default: null }) currtent!: DeptModel;
  // 公司id
  @Prop({ default: ''}) groupId!: string;
  // 表单加载状态
  formLoading: boolean = false;
  // 上级机构组下拉列表加载状态
  parentDeptGroupSelectListLoading: boolean = false;
  // 上级机构组下拉列表
  parentDeptGroupSelectList: any[] = [];
  // 表单数据
  companyData: any = {
    disabled: true
  };
  // 验证规则
  formRules: Record<string, any> = {
    name: {
      required: true,
      message: this.$t('lang_please_enter_the_company_name'),
      trigger: 'blur'
    },
    shortName: {
      required: true,
      message: this.$t('lang_please_enter_the_company_short_name'),
      trigger: 'blur'
    },
    pid: {
      required: true,
      message: this.$t('lang_please_select_a_parent_company'),
      trigger: 'change'
    },
    groupCode: {
      required: true,
      message: this.$t('lang_please_enter_the_company_code'),
      trigger: 'blur'
    },
    companyType: {
      required: true,
      message: this.$t('lang_please_entry_the_company_type'),
      trigger: 'change'
    },
    sort: {
      required: true,
      message: this.$t('lang_form_please_enter_a_sort'),
      trigger: 'blur'
    },
    disabled: {
      required: true,
      message: this.$t('lang_please_select_a_status'),
      trigger: 'blur'
    }
  };

  menuTreeNormalizer(node) {
    return {
      id: node.id,
      label: node.name,
      children: node.children
    };
  }

  created() {
    this.getParentDeptGroupSelectList();
    if (this.currtent) {
      this.companyData = { ...this.currtent };
    } else {
      this.companyData = {
        disabled: true,
        type: '0'
      };
    }
  }

  // 获取上级机构组下拉列表
  getParentDeptGroupSelectList() {
    this.parentDeptGroupSelectListLoading = true;
    getCompanyTreeData({ parentId: this.groupId, type: '0'})
      .then((res: Record<any, any>) => {
        let flag = false;
        if (res.data && res.data.length > 0) {
          res.data.forEach(item => {
            if (item.pid == '-1') {
              return flag = true;
            }
            return flag = false;
          })
        }
        if (flag) {
          this.parentDeptGroupSelectList = [{ id: '-1', name: this.$t('lang_top_node'), children: res.data }];
        } else {
          this.parentDeptGroupSelectList = res.data;
        }
      })
      .finally(() => {
        this.parentDeptGroupSelectListLoading = false;
      });
  }

  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      saveCompany(this.companyData)
        .then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // 关闭弹窗
            this.handleCloseDialog();
            this.$emit('success');
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          // 关闭加载框
        });
    });
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
