var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t("lang_invoice_magagement"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticStyle: { "margin-bottom": "10px" } },
            [
              _c("el-button", {
                attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
                on: {
                  click: function ($event) {
                    return _vm.addForm()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.invoiceListLoading,
                  expression: "invoiceListLoading",
                },
              ],
              staticClass: "page-table",
              attrs: {
                border: "",
                "row-key": "id",
                data: _vm.invoiceList,
                "empty-text": _vm.$t("lang_no_data"),
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_invoice_header"),
                  align: "center",
                  prop: "title",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_duty_paragraph"),
                  align: "center",
                  prop: "dutyParagraph",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_company_address"),
                  align: "center",
                  prop: "address",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_phone"),
                  align: "center",
                  prop: "phoneNumber",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_deposit_bank"),
                  align: "center",
                  prop: "depositBank",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("lang_bank_account"),
                  align: "center",
                  prop: "bankAccount",
                },
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("lang_operation"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              color: "#007aff",
                              "margin-left": "10px",
                            },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.addForm(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("lang_edit")))]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              "cancel-button-text": _vm.$t("lang_cancel_"),
                              "confirm-button-text": _vm.$t("lang_determine_"),
                              title: _vm.$t(
                                "lang_are_you_sure_to_delete_this_invoice"
                              ),
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.remove(scope.row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#e03e2d",
                                  "margin-left": "10px",
                                },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(_vm.$t("lang_delete")))]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "page-pager",
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.changePageSize,
              "current-change": _vm.changePage,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
      _vm.isShowAddEdit
        ? _c("invoice-form", {
            attrs: {
              visible: _vm.isShowAddEdit,
              groupId: _vm.groupId,
              tenantId: _vm.tenantId,
              currtent: _vm.currtent,
            },
            on: {
              "update:visible": function ($event) {
                _vm.isShowAddEdit = $event
              },
              success: _vm.getInvoicePage,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }